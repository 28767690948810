import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import assemblyReducer from './assemblyReducer';
import projectReducer from './projectReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  assembly: assemblyReducer,
  project: projectReducer,
  form: formReducer,
});

export default rootReducer;
