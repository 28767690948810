import React from 'react';
import { BrowserRouter, Router } from 'react-router-dom';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
//import MomentUtils from '@date-io/moment';

//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
// import LocalizationProvider from '@mui/lab/LocalizationProvider';

//import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import { AdapterDateFns } from '@mui/x-date-pickers';

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';


import { SnackbarProvider } from 'notistack';
import {
  ThemeProvider
} from '@mui/material';
import { jssPreset, makeStyles, createStyles, StylesProvider } from '@mui/styles';


import Auth from 'src/components/Auth';
import CookiesNotification from 'src/components/CookiesNotification';
//import SettingsNotification from 'src/components/SettingsNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import ScrollReset from 'src/components/ScrollReset';
import useSettings from 'src/hooks/useSettings';
import { createAppTheme } from 'src/theme';
//import AppRoutes from 'src/AppRoutes';

import Routes from 'src/routes';

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      height: '100%',
      width: '100%'
    },
    '#root': {
      height: '100%',
      width: '100%'
    }
  }
}));

function App() {
  useStyles();

  const { settings } = useSettings();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
    <ThemeProvider theme={createAppTheme(settings)}>
      <StylesProvider jss={jss}>
          <SnackbarProvider maxSnack={1}>
            <BrowserRouter>
              <Auth>
                <ScrollReset />
                <GoogleAnalytics />
                <CookiesNotification />
                <Routes />
              </Auth>
            </BrowserRouter>
          </SnackbarProvider>
      </StylesProvider>
    </ThemeProvider>
    </LocalizationProvider>
  );
}

/*

//                <AppRoutes />

AFter Cookies Notification
<SettingsNotification />

*/

export default App;
