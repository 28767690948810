
class AssemblyCategories {

  constructor(updatedBy, list){
    this.assemblyCategories = list;
    this.engineerId = updatedBy;
  }

}

class AssemblyMaster {

  constructor(updatedBy, list){
    this.assemblyMasterList = list;
    this.engineerId = updatedBy;
  }

}


export default { AssemblyCategories };
export { AssemblyCategories, AssemblyMaster };
