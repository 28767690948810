import { lazy } from 'react';


import AuthGuard from "src/components/AuthGuard";

// project imports
import DashboardLayout from "src/layouts/DashboardLayout";
import Loadable from 'src/components/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('src/views/dashboards/DashboardView')));
const ProductListView = Loadable(lazy(() => import('src/views/management/products/ProductListView')));
const ProductCreateView = Loadable(lazy(() => import('src/views/management/products/ProductCreateView')));
const ProductEditView = Loadable(lazy(() => import('src/views/management/products/ProductEditView')));
const ProjectListView = Loadable(lazy(() => import('src/views/management/projects/ProjectListView')));
const AllProjectListView = Loadable(lazy(() => import('src/views/management/projects/AllProjectListView')));
const ProjectCreateView = Loadable(lazy(() => import('src/views/management/projects/ProjectCreateView')));
const AssetView = Loadable(lazy(() => import('src/views/management/projects/AssetView')));
const ProjectView = Loadable(lazy(() => import('src/views/management/projects/ProjectView')));
const AssemblyMasterCategoryView = Loadable(lazy(() => import('src/views/management/master/AssemblyMasterCategoryView')));
const AssemblyMasterView = Loadable(lazy(() => import('src/views/management/master/AssemblyMasterView')));
const ProjectReportView = Loadable(lazy(() => import('src/views/management/projects/ProjectReportView')));

// ==============================|| MAIN ROUTING ||============================== //

const DashboardRoutes = {
    path: '/app',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
        {
            path: '/app',
            element: <DashboardDefault />
        },
        {
            path: '/app/management/projects/my',
            element: <ProjectListView />
        },
        {
            path: '/app/management/projects/all',
            element: <AllProjectListView />
        },
        {
            path: '/app/management/projects/create',
            element: <ProjectCreateView/>
        },
        {
            path: '/app/management/project',
            element: <ProjectView/>
        },
        {
            path: '/app/management/dashboard',
            element: <DashboardDefault />
        },
        {
            path: '/app/management/assets/create',
            element: <AssetView/>
        },
        {
            path: "/app/management/project/assets",
            element: <DashboardDefault />
        },
        {
            path: "/app/management/analysis/inputs",
            element: <DashboardDefault />
        },
        {
            path: "/app/management/analysis/calculation",
            element: <DashboardDefault />
        },
        {
            path: "/app/management/master/assembly/categories",
            element: <AssemblyMasterCategoryView />
        },
        {
            path: "/app/management/master/assembly/masters",
            element: <AssemblyMasterView />
        },
        {
            path: "/app/management/reports/schedules",
            element: <DashboardDefault />
        },
        {
            path: "/app/management/reports/reports",
            element: <ProjectReportView />
        },
        {
            path: "/app/account",
            element: <DashboardDefault />
        },

        // {
        //     path: "/app/management/catalog/product/edit/:productId",
        //     element: <ProductEditView />
        // }
    ]
};

export default DashboardRoutes;
