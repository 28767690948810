import React from "react";

import { Grid, Typography } from "@mui/material";

const CopyRight = () => {

  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          backgroundColor: "black",
          p: 2,
        }}
      >
        <Typography
          align={"center"}
          variant={"subtitle2"}
          color="primary.contrastText"
          //color="text.secondary"
          gutterBottom
        >
          Copyright &copy; 2023. All rights reserved by CS Ninja. Powered by MindsClik Digital Solutions Private Limited
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CopyRight;
