
class Project {

  constructor(updatedBy, info) {
    //console.log('Product(): ' + JSON.stringify(info));

    this.projectName = info.projectName;
    this.entityName = info.entityName;
    this.streetAddress = info.streetAddress;
    this.city = info.city;
    this.state = info.state;
    this.zipCode = info.zipCode;
    this.projectType = info.projectType;
//    this.propertyType = info.propertyType;
    this.studyType = info.studyType;
//    this.depreciationSystem = info.depreciationSystem;
//    this.assetClassMACRS = info.assetClassMACRS
    this.buildingIndirects = info.buildingIndirects;
    this.projectIndirects = info.projectIndirects;
    this.historicalFactor = info.historicalFactor;
    this.locationFactor = info.locationFactor;
    this.basisAdjustmentFactor = info.basisAdjustmentFactor;
    this.percentAdjustmentFactor = info.percentAdjustmentFactor;
    this.engineerId = updatedBy;
  }
}

class PropertyInfo {

  constructor(updatedBy, projectId, info) {
    //console.log('Product(): ' + JSON.stringify(info));

    this.projectId = projectId;
    this.yearBuilt = info.yearBuilt;
    this.yearPIS = info.yearPIS;
    this.propertyCondition = info.propertyCondition;
    this.propertyConditionFactor = info.propertyConditionFactor;
    this.actualAge = info.actualAge;
    this.effectiveAge = info.effectiveAge;
    this.normalLife = info.normalLife;
    this.engineerId = updatedBy;
  }
}

class Asset {

  constructor(updatedBy, projectId, info) {
    //console.log('Product(): ' + JSON.stringify(info));

    this.projectId = projectId;
    this.id = info.id;
    this.assetId = info.assetId;
    this.assetName = info.assetName;
    this.assetType = info.assetType;
    this.assetClass = info.assetClass?.assetClass;

    this.depreciationSystem = info.depreciationSystem;
    this.depreciableBasis = info.depreciableBasis;

    this.yearBuilt = info.yearBuilt;
    this.placedInService = info.placedInService;
    this.pisDate = info.pisDate;
    this.actualAge = info.actualAge;
    this.effectiveAge = info.effectiveAge;
    this.propertyCondition = info.propertyCondition;
    this.propertyConditionFactor = info.propertyConditionFactor;
    this.clientProvidedCost = info.clientProvidedCost;

    this.assetClassRP = info.assetClassRP?.assetClass;
    this.assetClassPP = info.assetClassPP?.assetClass;
    this.assetClassSI = info.assetClassSI?.assetClass;
    this.assetClassIS = info.assetClassIS?.assetClass;
    this.assetClassOT = info.assetClassOT?.assetClass;
    this.assetClassQIP = info.assetClassQIP?.assetClass;

    // this.percentAdjustmentFactor = info.percentAdjustmentFactor;
    this.engineerId = updatedBy;
  }
}

class AssetDeleteInfo {
  constructor( updatedBy, projectId, assetId, id){
    this.projectId = projectId;
    this.id = id;
    this.assetId = assetId;
    this.updatedBy = updatedBy;
  }
}


class Tenant {

  constructor(updatedBy, projectId, assetId, info) {
    //console.log('Product(): ' + JSON.stringify(info));
    this.id = info.id;
    this.projectId = projectId;
    this.assetId = assetId;
    this.tenantId = info.tenantId;
    this.tenantName = info.tenantName;
    this.engineerId = updatedBy;
  }
}



class ProjectAnalysis {

  constructor(updatedBy, projectId, assetId, tenantId, info) {
    //console.log('Product(): ' + JSON.stringify(info));

    this.projectId = projectId;
    this.assetId = assetId;
    this.tenantId = tenantId;

    this.analysisId = info.analysisId;
    this.assemblyMaster = info.assemblyMaster;
    this.buildingSystem = info.buildingSystem;
    this.assetClassType = info.assetClassType;
    this.assetClass = info.assetClass?.assetClass;
    this.csiDescription = info.csiDescription;
    this.csiMFCode = info.csiMFCode;
    this.assetLocation = info.assetLocatio;  // This is same as asset Id
    this.assemblyDescription = info.assemblyDescription;
    this.takeOffMethod = info.takeOffMethod;
    this.methodCode = info.methodCode;
    this.takeOffQty = info.takeOffQty;
    this.rate = info.rate;
    this.uom = info.uom;
    this.clientProvidedCost = info.clientProvidedCost;    // actual cost
    this.contractCode = info.contractCode;  
    this.costType = info.costType;  
    this.actOrEst = info.actOrEst;  // Note: actual, est or 179D study
    this.costSource = info.costSource ;
    this.costType = info.costType ;
    this.trendedCost = info.trendedCost;
    this.percentAllocation = info.percentAllocation;
    this.rcn = info.rcn;
    this.rcnProjectIndirect = info.rcnProjectIndirect;   // This is RCN with project Allocable
    this.rcnBuildingIndirect = info.rcnBuildingIndirect;  // This is RCN with building Allocable
    this.rcnWithIndirect = info.rcnWithIndirect;      // This is RCN with Allocable
    this.rcnld = info.rcnld;
    this.allocatedCost = info.allocatedCost;
    this.macrsLife = info.macrsLife;
    this.effectiveAge = info.effectiveAge;
    this.actualAge = info.actualAge;
    this.depreciationFactor = info.depreciationFactor;
    this.nl = info.nl;
    this.estSource = info.estSource;
    this.rsmId = info.rsmId;
    this.rsmSource = info.rsmSource;
    this.remarks = info.remarks;
    this.engineerId = updatedBy;
  }
}

class DeleteProjectAnalysisInfo {
  constructor(analysisId, projectId, assetId, tenantId, userId) {
    this.analysisId = analysisId;
    this.projectId = projectId;
    this.assetId = assetId;
    this.tenantId = tenantId;
    this.userId = userId;
  }
}


class RSMForProjectAnalysis {

  constructor(updatedBy, projectId, assetId, tenantId, info) {
    //console.log('Product(): ' + JSON.stringify(info));

    this.projectId = projectId;
    this.assetId = assetId;
    this.tenantId = tenantId;
    this.rsms = info;

    this.engineerId = updatedBy;
  }
}

class RSMList {
  constructor(updatedBy, projectId, assetId, tenantId, assetName, tenantName, list) {
    this.projectId = projectId;
    this.assetId = assetId;
    this.assetName = assetName;
    this.tenantId = tenantId;
    this.tenantName = tenantName;
    this.rsmList = list;
    this.engineerId = updatedBy;
  }
}

class CPCList {
  constructor(updatedBy, projectId, assetId, tenantId, assetName, tenantName, list) {
    this.projectId = projectId;
    this.assetId = assetId;
    this.assetName = assetName;
    this.tenantId = tenantId;
    this.tenantName = tenantName;
    this.cpcList = list;
    this.engineerId = updatedBy;
  }
}

class CPCForProjectAnalysis {

  constructor(updatedBy, projectId, assetId, tenantId, info) {
    //console.log('Product(): ' + JSON.stringify(info));

    this.projectId = projectId;
    this.assetId = assetId;
    this.tenantId = tenantId;
    this.cpcs = info;

    this.engineerId = updatedBy;
  }
}

class IndirectsList {
  constructor(updatedBy, projectId, list) {
    this.projectId = projectId;
    this.assetId = undefined;
    this.assetName = undefined;
    this.tenantId = undefined;
    this.tenantName = undefined;
    this.indirectsList = list;
    this.engineerId = updatedBy;
  }
}


export default { Project };
export { Project, PropertyInfo, Asset, AssetDeleteInfo, Tenant, ProjectAnalysis, DeleteProjectAnalysisInfo, RSMList,CPCList, RSMForProjectAnalysis, CPCForProjectAnalysis, IndirectsList };
