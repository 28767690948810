import React from 'react';

function Logo(props) {
  return (
    <>
    <img
      alt="Logo"
      src="/static/csninja_logo.png"
      height={50}
      {...props}
    />
    </>
  );
}


// width={50}
export default Logo;
